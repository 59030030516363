import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EmpresaDto, EmpresaSchema } from "./Empresa.Dto";
import {
  EmpresaAtualizar,
  EmpresaBuscarPorUsuario,
  EmpresaIncluir,
} from "src/services/Entidade.Service";
import {
  FormatarCEP,
  FormatarCNPJ,
  MapearErrosServidorCampo,
  SoNumeros,
} from "src/hook/Functions";
import { useNavigate } from "react-router-dom";
import { useToast } from "src/componentes/Toast/Toast";
import { BuscarCep } from "src/services/ViaCEP";

const EmpresaCode = () => {
  const [loading, setLoading] = useState(false);
  const [incluir, setIncluir] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useForm<EmpresaDto>({
    resolver: yupResolver(EmpresaSchema),
    defaultValues: undefined,
  });
  const navigate = useNavigate();
  const { showToast } = useToast();

  useEffect(() => {
    const controller = new AbortController();
    const Load = async () => {
      setLoading(true);
      await EmpresaBuscarPorUsuario()
        .then((response) => {
          if (response.data.success) {
            const data = response.data.data as EmpresaDto;
            data.documento = FormatarCNPJ(data.documento);
            data.cep = FormatarCEP(data.cep);
            reset(data);
            setIncluir(false);
          } else {
            setIncluir(true);
          }
        })
        .catch((error) => {
          console.log(error);
          showToast({
            message: "Ocorreu erro ao buscar empresa",
            title: "Erro",
            variant: "Danger",
          });
          return undefined;
        })
        .finally(() => {
          setLoading(false);
        });
    };
    Load();
    return () => {
      controller.abort();
    };
  }, []);

  const buscarCep = (cep: string) => {
    if (SoNumeros(cep).length === 8) {
      BuscarCep(cep).then((response) => {
        setValue("bairro", response.bairro);
        setValue("endereco", response.logradouro);
        setValue("nomeMunicipio", response.localidade);
        setValue("codigoMunicipio", response.ibge);
        setValue("uf", response.uf);
      });
    }
  };

  const onSubmit = async (data: EmpresaDto, e: any) => {
    e.preventDefault();
    setLoading(true);
    data.cep = SoNumeros(data.cep);
    data.documento = SoNumeros(data.documento);
    if (incluir === true) {
      EmpresaIncluir(data)
        .then((response) => {
          if (response.data.success) {
            navigate("/inicio");
          } else {
            MapearErrosServidorCampo(response.data.notifications, setError);
            showToast({
              message: "Ocorreu erro ao incluir cadastro",
              title: "Erro",
              variant: "Danger",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      EmpresaAtualizar(data).then((response) => {
        if (response.data.success) {
          navigate("/inicio");
        } else {
          MapearErrosServidorCampo(response.data.notifications, setError);
          showToast({
            message: "Ocorreu erro ao incluir cadastro",
            title: "Erro",
            variant: "Danger",
          });
        }
      });
    }
    setLoading(false);
  };

  return {
    loading,
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
    buscarCep,
  };
};
export default EmpresaCode;
