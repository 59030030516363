import {
  FloatingLabel,
  InputGroup,
  Form,
  FormControlProps,
} from "react-bootstrap";
import { forwardRef } from "react";
import {
  FormatarCPF,
  FormatarDouble,
  FormatarTelefone,
  SoNumeros,
  RemoverZerosEsquerda,
  FormatarCNPJ,
  TodasMaiusculas,
  FormatarCEP,
  Capitalizar,
  TodasMinusculas,
} from "src/hook/Functions";
import { FieldError } from "react-hook-form";

type InputProps = {
  label: string;
  type?: FormControlProps["type"];
  size?: FormControlProps["size"];
  errorsMessage?: FieldError | undefined;
  inputMask?:
    | "int"
    | "double"
    | "text"
    | "cpf"
    | "cnpj"
    | "cnpj-cpf"
    | "fone"
    | "data"
    | "datahora"
    | "minusculas"
    | "maiusculas"
    | "cep"
    | "capitalizar";
  maxLength?: number;
  disabled?: boolean | undefined;
  readOnly?: boolean | undefined;
  onInputChange?: (value: string) => void;
};

export const FloatInputComponent = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type = "text",
      size = "sm",
      errorsMessage,
      inputMask = "text",
      maxLength,
      disabled = undefined,
      readOnly = undefined,
      onInputChange,
      ...props
    },
    ref
  ) => {
    if (inputMask === "data") {
      type = "date";
    }
    if (inputMask === "datahora") {
      type = "datetime-local";
    }

    const OnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      onInputChange && onInputChange(value);
      const sanitizedValue = RemoverZerosEsquerda(SoNumeros(value));

      const nativeEvent = e.nativeEvent as InputEvent;
      if (nativeEvent.inputType === "deleteContentBackward") {
        // A tecla Backspace foi pressionada
        // return;
      }

      if (inputMask === "double" || inputMask === "int") {
        if (sanitizedValue.length === 0) {
          e.target.value = "0";
          return;
        }
      }

      if (inputMask === "double") {
        e.target.value = FormatarDouble(sanitizedValue);
      }

      if (inputMask === "int") {
        e.target.value = sanitizedValue;
      }

      if (inputMask === "cpf") {
        e.target.value = FormatarCPF(value);
      }

      if (inputMask === "cnpj") {
        e.target.value = FormatarCNPJ(value);
      }

      if (inputMask === "cnpj-cpf") {
        if (SoNumeros(value).length === 14) {
          e.target.value = FormatarCNPJ(value);
        }
        if (SoNumeros(value).length === 11) {
          e.target.value = FormatarCPF(value);
        }
      }

      if (inputMask === "fone") {
        e.target.value = FormatarTelefone(value);
      }

      if (inputMask === "maiusculas") {
        e.target.value = TodasMaiusculas(value);
      }

      if (inputMask === "minusculas") {
        e.target.value = TodasMinusculas(value);
      }

      if (inputMask === "cep") {
        e.target.value = FormatarCEP(value);
      }

      if (inputMask === "capitalizar") {
        e.target.value = Capitalizar(value);
      }
    };

    return (
      <>
        <InputGroup hasValidation>
          <FloatingLabel label={label}>
            <Form.Control
              type={type}
              placeholder={label}
              size={size}
              isInvalid={!!errorsMessage?.message}
              ref={ref}
              {...props}
              onChange={OnInputChange}
              maxLength={maxLength}
              disabled={disabled}
              readOnly={readOnly}
            />
            {errorsMessage?.message?.toString() && (
              <Form.Control.Feedback type="invalid">
                {errorsMessage?.message?.toString()}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </InputGroup>
      </>
    );
  }
);
