import * as yup from "yup";

export interface ILogin {
  email: string;
  senha: string;
  lembrar: boolean;
}

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Digite um e-mail válido.")
    .required("Digite seu e-mail."),
  senha: yup.string().required("Digite sua senha."),
});
