import yup from "src/validators/yup.Module";
import {
  validateCNPJ,
  validateCep,
  validateIE,
  validateUF,
} from "validations-br";

export interface EmpresaDto {
  documento: string;
  nome: string;
  nomeFantasia: string;
  endereco: string;
  enderecoNumero: string;
  enderecoComplemento: string;
  bairro: string;
  codigoMunicipio: string;
  nomeMunicipio: string;
  uf: string;
  cep: string;
  codigoPais: string;
  nomePais: string;
  inscricaoEstadual: string;
  apelido: string;
}

export const EmpresaSchema = yup.object().shape({
  documento: yup
    .string()
    .required("CNPJ é obrigatório")
    .test("cnpj", "CNPJ inválido", (value) => validateCNPJ(value)),
  nome: yup
    .string()
    .required("Digite o nome.")
    .min(3, "O deve ter no mínimo 3 caracteres")
    .max(60, "O deve ter no máximo 60 caracteres."),
  nomeFantasia: yup
    .string()
    .required("Digite o nome fantasia.")
    .min(3, "O deve ter no mínimo 3 caracteres")
    .max(60, "O deve ter no máximo 60 caracteres."),
  endereco: yup
    .string()
    .required("Digite o endereco.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  enderecoNumero: yup
    .string()
    .required("Digite o número.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  enderecoComplemento: yup
    .string()
    .minNotRequired(3, "Deve ter no mínimo 3 caracteres.")
    .max(60, "Deve ter no máximo 60 caracteres."),
  bairro: yup
    .string()
    .required("Digite o bairro.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  codigoMunicipio: yup.string().matches(/^\d{7}$/, "Selecione o município."),
  nomeMunicipio: yup
    .string()
    .required("Digite o Município.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  uf: yup
    .string()
    .required("UF é obrigatório.")
    .test("uf", "UF inválido.", (value) => validateUF(value)),
  cep: yup
    .string()
    .required("CEP é obrigatório.")
    .test("cep", "CEP inválido.", (value) => validateCep(value)),
  inscricaoEstadual: yup
    .string()
    .required("IE é obrigatório.")
    .test("ie", "IE inválido.", function (value) {
      const uf = this.parent.uf;
      return validateIE(value, uf);
    }),
  apelido: yup
    .string()
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
});
