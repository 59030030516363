import CryptoJS from "crypto-js";

const useLocalStorageSec = () => {
  const secretKey = process.env.REACT_APP_SECRET_KEY as string;

  const encryptData = (data: any) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return encryptedData;
  };

  const decryptData = (encryptedData: any) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = JSON.parse(
        decryptedBytes.toString(CryptoJS.enc.Utf8)
      );
      return decryptedData;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const setItem = (key: string, value: any) => {
    const encryptedValue = encryptData(value);
    localStorage.setItem(key, encryptedValue);
  };

  const getItem = (key: string) => {
    const encryptedValue = localStorage.getItem(key);
    if (encryptedValue) {
      const decryptedValue = decryptData(encryptedValue);
      return decryptedValue;
    }
    return null;
  };

  return { setItem, getItem };
};

export default useLocalStorageSec;
