import * as yup from "yup";

yup.addMethod(yup.string, "minNotRequired", function (min, message) {
  return this.test("minNotRequired", message, function (value) {
    if (!value || value.length >= min) {
      return true;
    }
    return false;
  });
});

declare module "yup" {
  interface StringSchema {
    minNotRequired(min: number, message?: string): StringSchema;
  }
}
