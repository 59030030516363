import { useState } from "react";
import { MunicipioDto } from "src/pages/Privada/Entidade/Municipio/Municipio.Dto";
import { MunicipioBuscarPorNome } from "src/services/Entidade.Service";

export const ConsultaMunicipioModule = () => {
  const [loadingMunicipio, setLoadingMunicipio] = useState(false);

  const MunicipioBuscar = (query: string): Promise<MunicipioDto[]> => {
    setLoadingMunicipio(true);
    return new Promise<MunicipioDto[]>((resolve, reject) => {
      MunicipioBuscarPorNome(query)
        .then((response) => {
          if (response.data.success) {
            const data = response.data.data;
            resolve(data);
          } else {
            if (response.status === 204) {
              resolve([]);
            } else {
              response.data.notifications.forEach((err: any) =>
                console.log(err.message)
              );
              reject("Erro ao buscar dados");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject("Erro ao buscar dados");
        })
        .finally(() => {
          setLoadingMunicipio(false);
        });
    });
  };

  return {
    MunicipioBuscar,
    loadingMunicipio,
  };
};
