import { Form, Button, Card, FloatingLabel, InputGroup } from "react-bootstrap";
import { LoginCode } from "./Login.Code";
import styles from "./styles.module.css";
import { ButtonSpinner } from "../../../componentes";
import { AutenticacaoModule } from "src/Contexto/auth";

export function LoginView() {
  const { onSubmit, loading, onClickNavigate, handleSubmit, register, errors } =
    LoginCode();
  const { isLogged } = AutenticacaoModule();

  return (
    <div>
      {!isLogged && (
        <div
          className={`d-flex justify-content-center align-items-center ${styles.painel}`}
        >
          <Card className="col-11 col-sm-6 col-md-5 col-lg-4 col-3">
            <Card.Header>Logar no sistema</Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <InputGroup hasValidation>
                  <FloatingLabel className="mb-3" label="Email">
                    <Form.Control
                      type="email"
                      placeholder="Digite seu email"
                      className={errors.email ? "is-invalid" : ""}
                      {...register("email")}
                    ></Form.Control>
                    {errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </InputGroup>
                <InputGroup hasValidation>
                  <FloatingLabel className="mb-3" label="Senha">
                    <Form.Control
                      type={"password"}
                      placeholder="Digite sua senha"
                      className={errors.senha ? "is-invalid" : ""}
                      {...register("senha")}
                    ></Form.Control>
                    {errors.senha && (
                      <Form.Control.Feedback type="invalid">
                        {errors.senha.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </InputGroup>
                <Form.Group className="mb-3" controlId="">
                  <Form.Check type="checkbox" id="lembrar">
                    <Form.Check.Input
                      type="checkbox"
                      {...register("lembrar")}
                    ></Form.Check.Input>
                    <Form.Check.Label>Lembrar</Form.Check.Label>
                  </Form.Check>
                </Form.Group>
                <div className="d-grid gap-2">
                  <ButtonSpinner
                    variant="primary"
                    type="submit"
                    loading={loading}
                    data-bs-theme="light"
                  >
                    Acessar
                  </ButtonSpinner>
                </div>
                <Button
                  variant="link"
                  onClick={onClickNavigate("/cadastro")}
                  className="ps-0"
                >
                  Cadastre-se
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
}

export default LoginView;
