import { useEffect, useState } from "react";

export const TemaModule = () => {
  const [temaAtual, setTemaAtual] = useState("");

  useEffect(() => {
    if (temaAtual !== "") {
      GravarTemaLocalStorage(temaAtual);
      AplicaTemaPagina(temaAtual);
    }
  }, [temaAtual]);

  const BuscarTemaLocalStorage = (): string | null =>
    localStorage.getItem("theme");

  const GravarTemaLocalStorage = (theme: string): void =>
    localStorage.setItem("theme", theme);

  const BuscarTemaPreferencial = (): string => {
    return BuscarTemaLocalStorage() || "light";
  };

  const AplicaTemaPagina = (tema: string): void => {
    document.documentElement.setAttribute("data-bs-theme", tema);
  };

  const AlternarTema = (): void => {
    setTemaAtual(temaAtual === "dark" ? "light" : "dark");
  };

  const CarregarTema = (): void => {
    setTemaAtual(BuscarTemaLocalStorage() || BuscarTemaPreferencial());
  };

  return { CarregarTema, AlternarTema, temaAtual };
};
