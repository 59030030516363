import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AutenticacaoModule, AuthContext } from "../../../Contexto/auth";
import useLocalStorageSec from "../../../hook/useLocalStorageSec";

import { useToast } from "../../../componentes/Toast/Toast";
import { ILogin, LoginSchema } from "../../../interfaces/ILogin";
import { UsuarioEnviarConfirmacaoEmail } from "../../../services/Identity.Service";

export const LoginCode = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setItem, getItem } = useLocalStorageSec();
  const { isLogged } = AutenticacaoModule();
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ILogin>({
    resolver: yupResolver(LoginSchema),
  });

  useEffect(() => {
    const Load = () => {
      if (isLogged) {
        navigate("/inicio");
      }
    };
    Load();
  }, [isLogged, navigate]);

  useEffect(() => {
    if (getItem("login")) {
      setValue("email", getItem("login").email);
      setValue("senha", getItem("login").senha);
      setValue("lembrar", getItem("login").lembrar);
    }
  }, []);

  const onClickNavigate = (route: string) => () => {
    navigate(route);
  };

  const onSubmit = async (loginData: ILogin, event: any) => {
    event.preventDefault();
    setLoading(true);

    await authContext
      .logIn(loginData)
      .then((response) => {
        if (response.success) {
          if (loginData.lembrar) {
            setItem("login", loginData);
          }
          navigate("/inicio");
        } else {
          LoginError(response, loginData);
        }
      })
      .catch((error) => {
        console.error("Erro durante o login:", error);
        showToast({
          message: "Ocorreu um erro durante o login.",
          title: "Erro",
          variant: "Warning",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const LoginError = (response: any, loginData: ILogin) => {
    if (response.errors[0].message === "Usuário não liberado") {
      EmailNaoConfirmado(loginData);
    } else {
      showToast({
        message: response.errors[0].message,
        title: "Erro",
        variant: "Warning",
      });
    }
  };

  const EmailNaoConfirmado = async (loginData: ILogin) => {
    await UsuarioEnviarConfirmacaoEmail(loginData)
      .then((response) => {
        if (response.success) {
          showToast({
            message: "Email de confirmação enviado, verifique seu email!",
            variant: "Info",
          });
        } else {
          showToast({
            message: response.notifications[0].message,
            title: "Erro",
            variant: "Warning",
          });
        }
      })
      .catch((error) => {
        console.error("Erro durante o envio do email de confirmação:", error);
        showToast({
          message: "Ocorreu um erro durante o envio do email de confirmação.",
          title: "Erro",
          variant: "Warning",
        });
      });
  };

  return {
    onSubmit,
    loading,
    onClickNavigate,
    handleSubmit,
    register,
    errors,
  };
};
