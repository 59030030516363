import yup from "src/validators/yup.Module";

export interface CertificadoInfoDto {
  id: number;
  empresaId: number;
  nome: string;
  dataHoraValidade: Date;
  chave: string;
}

export interface CertificadoIncluirDto {
  arquivo: Blob[];
  chave: string;
}

export interface CertificadoInluirInputDto {
  arquivo: string;
  chave: string;
}

export const CertificadoIncluirSchema = yup.object().shape({
  arquivo: yup
    .mixed()
    .test("is-file-list", "Selecione o arquivo", (value) => {
      return value && value instanceof FileList && value.length > 0;
    })
    .required("Selecione o arquivo"),
  chave: yup.string().required("Obrigatório"),
});
