import { useEffect, useState } from "react";
import { MenuService } from "../../../services/Menu.Service";
import { MenuItemDto } from "./MenuItem.Dto";
import MessageBoxModule from "../../../hook/MessageBox.Module";
import { useNavigate } from "react-router-dom";
import { AutenticacaoModule } from "src/Contexto/auth";

export const NavbarTopCode = () => {
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItemDto[]>([]);
  const { ShowMessage } = MessageBoxModule();
  const navigate = useNavigate();
  const { logOut } = AutenticacaoModule();

  useEffect(() => {
    const Load = async () => {
      setLoading(true);
      await MenuService()
        .then((response) => {
          if (response.data.success) {
            const data = response.data.data;
            setMenuItems(data);
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          setMenuItems([]);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    Load();
  }, []);

  const SairMessage = () => {
    ShowMessage(
      "Deseja realmente sair da aplicação?",
      "Atenção!",
      "YesNo",
      "Info",
      {
        onYes: Sair,
      }
    );
  };

  const Sair = () => {
    logOut();
    navigate("/login");
  };

  return { menuItems, SairMessage, loading };
};
