import api from "./api";
import { EmpresaDto } from "src/pages/Privada/Entidade/Empresa/Empresa.Dto";
import { PessoaFiltrarDto } from "src/pages/Privada/Entidade/Pessoa/Pessoa.Dto";
import {
  PessoaCadastroConsultaDto,
  PessoaCadastroDto,
} from "src/pages/Privada/Entidade/Pessoa/Cadastro/PessoaCadastro.Dto";
import ApiResponse from "src/interfaces/ApiResponse";
import {
  CertificadoInfoDto,
  CertificadoInluirInputDto,
} from "src/pages/Privada/Entidade/Certificado/Certificado.Dto";

export function MunicipioBuscarPorNome(nome: string) {
  return api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE +
      "/municipio/buscar-por-nome?nome=" +
      nome
  );
}

export function EmpresaIncluir(empresa: EmpresaDto) {
  return api.post<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/empresa/incluir",
    empresa
  );
}

export function EmpresaBuscarPorUsuario() {
  return api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/empresa/buscar-por-usuario"
  );
}

export function EmpresaAtualizar(empresa: EmpresaDto) {
  return api.put<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/empresa/atualizar",
    empresa
  );
}

export async function PessoaFiltrar(input: PessoaFiltrarDto) {
  return await api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/pessoa/filtrar",
    {
      params: input,
    }
  );
}

export function PessoaBuscarPorId(id: string) {
  return api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE +
      "/pessoa/buscar-por-id?id=" +
      id
  );
}

export function PessoaIncluir(pessoa: PessoaCadastroDto) {
  return api.post<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/pessoa/incluir",
    pessoa
  );
}

export function PessoaAtualizar(pessoa: PessoaCadastroDto) {
  return api.put<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/pessoa/atualizar",
    pessoa
  );
}

export function CertificadoBuscarInfo() {
  return api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE +
      "/certificado/buscar-certificado-info"
  );
}

export function CertificadoIncluir(certificado: CertificadoInluirInputDto) {
  return api.post<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/certificado/incluir",
    certificado
  );
}

export function CertificadoAtualizar(certificado: CertificadoInluirInputDto) {
  return api.put<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/certificado/atualizar",
    certificado
  );
}

export function PessoaConsultaCadastro(consulta: PessoaCadastroConsultaDto) {
  return api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_ENTIDADE + "/pessoa/consulta-cadastro",
    {
      params: consulta,
    }
  );
}
