import { SoNumeros } from "src/hook/Functions";
import yup from "src/validators/yup.Module";
import {
  validateCNPJ,
  validateCPF,
  validateCep,
  validateIE,
  validateUF,
} from "validations-br";

export interface PessoaCadastroDto {
  id: number;
  documento: string;
  nome: string;
  nomeFantasia: string;
  endereco: string;
  enderecoNumero: string;
  enderecoComplemento: string;
  bairro: string;
  codigoMunicipio: string;
  nomeMunicipio: string;
  uf: string;
  cep: string;
  codigoPais: string;
  nomePais: string;
  inscricaoEstadual: string;
  apelido: string;
  perfilPessoa: number;
}

export interface PessoaCadastroConsultaDto {
  cnpj: string;
  uf: string;
}

export const PessoaCadastroConsultaSchema = yup.object().shape({
  cnpj: yup
    .string()
    .required("CNPJ é obrigatório")
    .test("documento", "CNPJ inválido", (value) => {
      if (SoNumeros(value).length === 14) {
        return validateCNPJ(value);
      }
      return false;
    }),
  uf: yup
    .string()
    .required("UF é obrigatório.")
    .test("uf", "UF inválido.", (value) => validateUF(value)),
});

export const PessoaCadastroSchema = yup.object().shape({
  documento: yup
    .string()
    .required("CNPJ/CPF é obrigatório")
    .test("documento", "CNPJ/CPF inválido", (value) => {
      if (SoNumeros(value).length === 14) {
        return validateCNPJ(value);
      } else if (SoNumeros(value).length === 11) {
        return validateCPF(value);
      }
      return false;
    }),
  nome: yup
    .string()
    .required("Digite o nome.")
    .min(3, "O deve ter no mínimo 3 caracteres")
    .max(60, "O deve ter no máximo 60 caracteres."),
  nomeFantasia: yup
    .string()
    .required("Digite o nome fantasia.")
    .min(3, "O deve ter no mínimo 3 caracteres")
    .max(60, "O deve ter no máximo 60 caracteres."),
  endereco: yup
    .string()
    .required("Digite o endereco.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  enderecoNumero: yup
    .string()
    .required("Digite o número.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  enderecoComplemento: yup
    .string()
    .minNotRequired(3, "Deve ter no mínimo 3 caracteres.")
    .max(60, "Deve ter no máximo 60 caracteres."),
  bairro: yup
    .string()
    .required("Digite o bairro.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  codigoMunicipio: yup.string().matches(/^\d{7}$/, "Selecione o município."),
  nomeMunicipio: yup
    .string()
    .required("Digite o Município.")
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(60, "O deve ter no máximo 60 caracteres."),
  uf: yup
    .string()
    .required("UF é obrigatório.")
    .test("uf", "UF inválido.", (value) => validateUF(value)),
  cep: yup
    .string()
    .required("CEP é obrigatório.")
    .test("cep", "CEP inválido.", (value) => validateCep(value)),
  inscricaoEstadual: yup.string().test("ie", "IE inválido.", function (value) {
    if (value !== "" && value !== undefined) {
      const uf = this.parent.uf;
      return validateIE(value, uf);
    } else {
      return true;
    }
  }),
  apelido: yup
    .string()
    .min(3, "O deve ter no mínimo 3 caracteres.")
    .max(10, "O deve ter no máximo 10 caracteres."),
  perfilPessoa: yup
    .number()
    .typeError("Selecione o perfil")
    .required("Selecione o perfil"),
});
