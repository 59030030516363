import styles from "./styles.module.css";

const NaoEncontrada = () => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.painel}`}
    />
  );
};

export default NaoEncontrada;
