// Arquivo: MenuContext.js
import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItemDto } from "src/pages/Privada/Menu/MenuItem.Dto";
import { MenuService } from "src/services/Menu.Service";

interface IMenuContext {
  menuItems: MenuItemDto[] | undefined;
  loading: boolean;
  loadMenu: () => void;
}

const MenuContext = createContext<IMenuContext>({} as IMenuContext);

export const MenuProvider = ({ children }: any) => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loadMenu = async () => {
    setLoading(true);
    try {
      const response = await MenuService();
      if (response.data.success) {
        const data = response.data.data;
        setMenuItems(data);
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      menuItems: menuItems,
      loading: loading,
      loadMenu,
    }),
    [menuItems, loading, loadMenu]
  );

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
