import { Outlet, Navigate, useLocation } from "react-router-dom";

import { MenuView } from "../pages/Privada/Menu/Menu.View";
import { AutenticacaoModule } from "src/Contexto/auth";

export const PrivateRoute = (element: any) => {
  const { user, isLoaded } = AutenticacaoModule();

  if (!user && isLoaded) {
    return <Navigate to="/login" />;
  }

  return (
    <MenuView>
      <Outlet />
    </MenuView>
  );
};
