import React from "react";
import { Dropdown } from "react-bootstrap";

interface MenuDropdownToggleProps {
  children: React.ReactNode;
}

export function MenuDropdownToggle({ children }: MenuDropdownToggleProps) {
  return (
    <Dropdown.Toggle
      id="menu-dropdown-toggle"
      variant="transparent"
      className="menu-dropdown-toggle"
    >
      {children}
    </Dropdown.Toggle>
  );
}
