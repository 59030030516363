import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import LoginView from "../pages/Publica/Login/Login.View";
import SignInView from "../pages/Publica/SignIn/SignIn.View";
import NaoEncontrada from "../pages/Publica/NaoEncontrada/NaoEncontrada.View";
import EmpresaView from "../pages/Privada/Entidade/Empresa/Empresa.View";
import InicioView from "../pages/Privada/Inicio/Inicio.View";
import UsuarioView from "../pages/Privada/Configuracoes/Usuario/Usuario.View";
import UsuarioCadastroView from "../pages/Privada/Configuracoes/Usuario/Cadastro/UsuarioCadastro.View";
import MinhaContaView from "../pages/Privada/MinhaConta/MinhaConta.View";
import PessoaView from "src/pages/Privada/Entidade/Pessoa/Pessoa.View";
import PessoaCadastroView from "src/pages/Privada/Entidade/Pessoa/Cadastro/PessoaCadastro.View";
import CertificadoView from "src/pages/Privada/Entidade/Certificado/Certificado.View";

export const Rotas = () => (
  <Routes>
    <Route path="/" element={<PrivateRoute />}>
      <Route path="/inicio" element={<InicioView />} />
      <Route path="/minhaconta" element={<MinhaContaView />} />
      <Route path="/entidade/empresa" element={<EmpresaView />} />
      <Route path="/entidade/certificado" element={<CertificadoView />} />
      <Route path="/entidade/pessoa" element={<PessoaView />} />
      <Route
        path="/entidade/pessoa/cadastro"
        element={<PessoaCadastroView />}
      />
      <Route path="/usuario" element={<UsuarioView />} />
      <Route path="/usuario/cadastro" element={<UsuarioCadastroView />} />
    </Route>
    <Route path="/cadastro" element={<SignInView />} />
    <Route path="/login" element={<LoginView />} />
    <Route path="*" element={<NaoEncontrada />} />
  </Routes>
);
