import { useEffect, useState } from "react";
import { PessoaDto, PessoaFiltrarDto, PessoaFiltrarSchema } from "./Pessoa.Dto";
import { PessoaFiltrar } from "src/services/Entidade.Service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "src/componentes";
import { MapearErrosServidor } from "src/hook/Functions";

const PessoaCode = () => {
  const [pessoaData, setUsuarioData] = useState<PessoaDto[] | undefined>([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm<PessoaFiltrarDto>({
    resolver: yupResolver(PessoaFiltrarSchema),
  });

  useEffect(() => {
    const controller = new AbortController();
    const Load = async () => {};

    Load();
    return () => {
      controller.abort();
    };
  }, []);

  const onSubmit = async (data: PessoaFiltrarDto, e: any) => {
    setLoading(true);
    try {
      const response = await PessoaFiltrar(data);
      if (response.data.success) {
        const data = response.data.data;
        setUsuarioData(data);
        if (data.length == 0) {
          showToast({
            message: "Não encontrado",
            title: "Info",
            variant: "Info",
          });
        }
      } else {
        MapearErrosServidor(response.data.notifications, showToast);
      }
    } catch (error) {
      setUsuarioData(undefined);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    pessoaData,
    loading,
    register,
    errors,
    handleSubmit,
    onSubmit,
  };
};
export default PessoaCode;
