// ToastProvider.js
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface IToastContext {
  showToast: (options: IToastOptions) => Promise<void>;
}

export interface IToastOptions {
  message: string;
  title?: string;
  variant?:
    | "Primary"
    | "Secondary"
    | "Success"
    | "Danger"
    | "Warning"
    | "Info"
    | "Light"
    | "Dark";
}

const ToastContext = createContext<IToastContext>({} as IToastContext);

export const useToast = () => {
  return useContext(ToastContext);
};

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toast, setToast] = useState<IToastOptions | null>();

  const showToast = useCallback(async (options: IToastOptions) => {
    setToast(options);
  }, []);

  const hideToast = () => {
    setToast(null);
  };

  const value = useMemo(
    () => ({
      showToast,
    }),
    [showToast]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ zIndex: 9999 }}
      >
        {toast && (
          <Toast
            onClose={hideToast}
            show={!!toast}
            delay={10000}
            autohide
            bg={toast.variant?.toLocaleLowerCase()}
          >
            {toast.title && <Toast.Header>{toast.title}</Toast.Header>}
            <Toast.Body>{toast.message}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
