import { MinhaContaDto } from "src/pages/Privada/MinhaConta/MinhaConta.Dto";

import { ILogin } from "../interfaces/ILogin";
import { IRefreshToken } from "../interfaces/IRefreshToken";
import { SigninDto } from "../pages/Publica/SignIn/Signin.Dto";
import api from "./api";
import ApiResponse from "src/interfaces/ApiResponse";

export function AcessoLogin(loginData: ILogin) {
  return api
    .post<ApiResponse>(
      process.env.REACT_APP_API_BASE_URL_IDENTITY + "/acesso/token-gerar",
      loginData
    )
    .then((response) => response.data);
}

export function AcessoRefreshToken(refreshTokenData: IRefreshToken) {
  return api.post(
    process.env.REACT_APP_API_BASE_URL_IDENTITY + "/acesso/token-refresh",
    refreshTokenData
  );
}

export function UsuarioAdicionar(signinData: SigninDto) {
  return api
    .post<ApiResponse>(
      process.env.REACT_APP_API_BASE_URL_IDENTITY + "/usuario/adicionar",
      signinData
    )
    .then((response) => response.data);
}

export function UsuarioGestaoBuscarTodos() {
  return api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_IDENTITY + "/usuario-gestao/buscar-todos"
  );
}

export function UsuarioGestaoBuscarPorId(id: string) {
  return api.get<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_IDENTITY +
      "/usuario-gestao/buscar-por-id?id=" +
      id
  );
}

export function UsuarioEnviarConfirmacaoEmail(loginData: ILogin) {
  return api
    .post<ApiResponse>(
      process.env.REACT_APP_API_BASE_URL_IDENTITY +
        "/usuario/enviar-confirmacao-email?email=" +
        loginData.email
    )
    .then((response) => response.data);
}

export function UsuarioGestaoAtualizar(usuario: MinhaContaDto) {
  return api.post<ApiResponse>(
    process.env.REACT_APP_API_BASE_URL_IDENTITY + "/usuario-gestao/atualizar",
    usuario
  );
}
