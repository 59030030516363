import * as yup from "yup";

export interface SigninDto {
  email: string;
  senha: string;
  repetirSenha: string;
}

export const SigninSchema = yup.object().shape({
  email: yup
    .string()
    .email("Digite um e-mail válido.")
    .required("Digite seu e-mail."),
  senha: yup.string().required("Digite sua senha."),
  repetirSenha: yup
    .string()
    .required("Repita a senha.")
    .oneOf([yup.ref("senha"), ""], "As senhas devem ser iguais."),
});
