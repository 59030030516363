import { useEffect, useState } from "react";
import { Dropdown, Nav, Navbar, Offcanvas, Placeholder } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { NavbarTopCode } from "./NavbarTop.Code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faHome,
  faMoon,
  faRightFromBracket,
  faSun,
  faUser,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
import { library, icon } from "@fortawesome/fontawesome-svg-core";

import Image from "react-bootstrap/Image";
import LogoAgiliza from "../../../img/logoagiliza.png";
import { TemaModule } from "../../../hook/Tema.Module";
library.add(faHome, faGear, faUser, faBox);

const NavbarTopView = () => {
  const [show, setShow] = useState(false);
  const { menuItems, SairMessage, loading } = NavbarTopCode();
  const { temaAtual, AlternarTema, CarregarTema } = TemaModule();

  const handleShowHide = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    CarregarTema();
  }, []);

  return (
    <Navbar expand="true" collapseOnSelect className="mb-3 bg-primary">
      <Navbar.Toggle onClick={handleShowHide} />

      <Dropdown>
        <Dropdown.Toggle>
          <FontAwesomeIcon icon={faUser} />
        </Dropdown.Toggle>

        <Dropdown.Menu align="end">
          <Dropdown.Item as={NavLink} to="/minhaconta">
            Minha Conta
          </Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Item onClick={AlternarTema}>
            <FontAwesomeIcon
              className="dropdown-menu-icon-padding"
              icon={temaAtual === "light" ? faMoon : faSun}
            />
            {temaAtual === "light" ? "Modo Escuro" : "Modo Claro"}
          </Dropdown.Item>
          <Dropdown.Item onClick={SairMessage}>
            <FontAwesomeIcon
              className="dropdown-menu-icon-padding"
              icon={faRightFromBracket}
            />
            Sair
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Navbar.Offcanvas
        id="offcanvasNavbar-expand"
        aria-labelledby="offcanvasNavbarLabel-expand"
        placement="start"
        show={show}
        onHide={handleShowHide}
      >
        <Offcanvas.Header closeButton>
          <Image
            src={LogoAgiliza}
            alt="Descrição da imagem"
            fluid
            style={{ width: "150px", height: "auto" }}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loading ? (
            <Nav
              defaultActiveKey="/inicio"
              variant="pills"
              className="nav flex-column mb-auto"
              navbar={false}
            >
              {menuItems
                ?.filter((item) => item.nivel === 1)
                .sort((a, b) => a.sequencia - b.sequencia)
                .map((item) =>
                  item.rota ? (
                    <Nav.Item key={item.id}>
                      <Nav.Link
                        as={NavLink}
                        to={item.rota}
                        onClick={handleClose}
                      >
                        <FontAwesomeIcon
                          className="dropdown-menu-icon-padding"
                          icon={icon({
                            prefix: "fas",
                            iconName: item.icone,
                          })}
                        />
                        {item.nome}
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <Dropdown className="dropdown-menu-padding" key={item.id}>
                      <Dropdown.Toggle
                        as={NavLink}
                        to="#"
                        id="dropdown-basic"
                        className="dropdown-menu-toggle"
                      >
                        <FontAwesomeIcon
                          className="dropdown-menu-icon-padding"
                          icon={icon({
                            prefix: "fas",
                            iconName: item.icone,
                          })}
                        />
                        {item.nome}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="dropdown-menu-position"
                        align="start"
                      >
                        {menuItems
                          ?.filter(
                            (subItem) =>
                              subItem.nivel === 2 &&
                              subItem.idNivelAnterior === item.id
                          )
                          .sort((a, b) => a.sequencia - b.sequencia)
                          .map((subItem) => (
                            <Dropdown.Item
                              as={NavLink}
                              to={subItem.rota}
                              onClick={handleClose}
                              key={subItem.id}
                            >
                              {subItem.nome}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                )}
            </Nav>
          ) : (
            <Placeholder animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </Placeholder>
          )}
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  );
};

export default NavbarTopView;
