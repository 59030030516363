import {
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  Placeholder,
  Row,
  Table,
} from "react-bootstrap";
import UsuarioCode from "./Usuario.Code";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IRoute } from "../../../../interfaces/IRoute";
import { HeadPage } from "src/componentes";

const UsuarioView = () => {
  const { usuarioData, loading } = UsuarioCode();
  const navigate = useNavigate();
  const [menuItens, setMenuItens] = useState<IRoute[]>([]);

  const handleClickAlterar = (id: number) => () => {
    navigate(`/usuario/cadastro?id=${id}`);
  };

  const handleClickNavigate = (route: string) => () => {
    navigate(route);
  };

  useEffect(() => {
    setMenuItens([{ name: "Novo Usuário...", route: "/usuario/cadastro" }]);
  }, []);

  return (
    <Container>
      <HeadPage headText="Usuários">
        {menuItens !== undefined &&
          menuItens.map((item) => (
            <Dropdown.Item
              key={item.name}
              onClick={handleClickNavigate(item.route)}
            >
              {item.name}
            </Dropdown.Item>
          ))}
      </HeadPage>
      <Row>
        <Col>
          <FloatingLabel controlId="pesquisar" label="Pesquisar">
            <Form.Control type="text" placeholder="Pesquisar" />
          </FloatingLabel>
        </Col>
      </Row>

      <Row>
        {!loading ? (
          <Col>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th className="col-2">Nome</th>
                  <th className="d-none d-sm-table-cell col-10">Email</th>
                </tr>
              </thead>
              <tbody>
                {usuarioData?.map((item) => (
                  <tr
                    key={item.id}
                    onClick={handleClickAlterar(item.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="col-2">
                      {item.nome} {item.sobrenome}
                    </td>
                    <td className="d-none d-sm-table-cell col-10">
                      {item.email}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        ) : (
          <Placeholder as={FloatingLabel} animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        )}
      </Row>
    </Container>
  );
};

export default UsuarioView;
