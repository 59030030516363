import yup from "src/validators/yup.Module";

export interface PessoaDto {
  id: number;
  documento: string;
  nome: string;
  nomeFantasia: string;
  endereco: string;
  enderecoNumero: string;
  enderecoComplemento: string;
  bairro: string;
  codigoMunicipio: string;
  nomeMunicipio: string;
  uf: string;
  cep: string;
  codigoPais: string;
  nomePais: string;
  inscricaoEstadual: string;
  apelido: string;
}

export interface PessoaFiltrarDto {
  nome: string;
  perfilPessoa: number;
}

export const PessoaFiltrarSchema = yup.object().shape({
  nome: yup
    .string()
    .notRequired()
    .min(3, "O nome deve ter no mínimo 3 caracteres"),
});
