import { useEffect, useState } from "react";
import { MinhaContaDto, MinhaContaSchema } from "./MinhaConta.Dto";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  UsuarioGestaoAtualizar,
  UsuarioGestaoBuscarPorId,
} from "src/services/Identity.Service";
import { useNavigate } from "react-router-dom";
import { useToast } from "src/componentes/Toast/Toast";
import {
  FormatarCPF,
  FormatarTelefone,
  MapearErrosServidor,
  MapearErrosServidorCampo,
  SoNumeros,
} from "src/hook/Functions";
import moment from "moment";
import { AutenticacaoModule } from "src/Contexto/auth";

const MinhaContaCode = () => {
  const [loading, setLoading] = useState(false);
  const { userId } = AutenticacaoModule();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<MinhaContaDto>({
    resolver: yupResolver(MinhaContaSchema),
    defaultValues: undefined,
  });

  useEffect(() => {
    const controller = new AbortController();
    const Load = async () => {
      setLoading(true);
      await UsuarioGestaoBuscarPorId(userId.toString())
        .then((response) => {
          if (response.data.success) {
            const data = response.data.data as MinhaContaDto;
            data.documento = FormatarCPF(data.documento);
            data.numeroTelefone = FormatarTelefone(data.numeroTelefone);
            reset(data);
          } else {
            console.log(response.data.notifications);
          }
        })
        .catch((error) => {
          console.log(error);
          return undefined;
        })
        .finally(() => {
          setLoading(false);
        });
    };
    Load();
    return () => {
      controller.abort();
    };
  }, []);

  const onSubmit = async (data: MinhaContaDto, e: any) => {
    e.preventDefault();
    setLoading(true);
    data.id = userId;
    data.dataNascimento = moment(data.dataNascimento).format("YYYY-MM-DD");
    data.documento = SoNumeros(data.documento);
    data.numeroTelefone = SoNumeros(data.numeroTelefone);

    UsuarioGestaoAtualizar(data)
      .then((response) => {
        if (response.data.success) {
          navigate("/inicio");
        } else {
          MapearErrosServidorCampo(response.data.notifications, setError);
          showToast({
            message: "Ocorreu erro ao atualizar cadastro",
            title: "Erro",
            variant: "Danger",
          });
          MapearErrosServidor(response.data.notifications, showToast);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  return {
    loading,
    register,
    handleSubmit,
    errors,
    onSubmit,
  };
};

export default MinhaContaCode;
