import { Button, Card, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import SiginInCode from "./SignIn.Code";
import { SigninDto, SigninSchema } from "./Signin.Dto";
import styles from "./styles.module.css";
import { ButtonSpinner } from "../../../componentes";

const SignInView = () => {
  const [showPassword] = useState(false);
  const { onSubmit, loading, onClickNavigate } = SiginInCode();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninDto>({
    resolver: yupResolver(SigninSchema),
  });

  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.painel} `}
    >
      <Card className="col-11 col-sm-6 col-md-5 col-lg-4 col-3">
        <Card.Header>Cadastrar-se no sistema</Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <InputGroup hasValidation>
              <FloatingLabel className="mb-3" label="Email">
                <Form.Control
                  type="email"
                  placeholder="Digite seu email"
                  className={errors.email ? "is-invalid" : ""}
                  {...register("email")}
                ></Form.Control>
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message?.toString()}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </InputGroup>
            <InputGroup hasValidation>
              <FloatingLabel className="mb-3" label="Senha">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Digite sua senha"
                  className={errors.senha ? "is-invalid" : ""}
                  {...register("senha")}
                ></Form.Control>
                {errors.senha && (
                  <Form.Control.Feedback type="invalid">
                    {errors.senha.message?.toString()}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </InputGroup>

            <InputGroup hasValidation>
              <FloatingLabel className="mb-3" label="Repetir senha">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Repetir senha"
                  className={errors.repetirSenha ? "is-invalid" : ""}
                  {...register("repetirSenha")}
                ></Form.Control>
                {errors.repetirSenha && (
                  <Form.Control.Feedback type="invalid">
                    {errors.repetirSenha.message?.toString()}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </InputGroup>

            <div className="d-grid gap-2">
              <ButtonSpinner variant="primary" type="submit" loading={loading}>
                Enviar Cadastro
              </ButtonSpinner>
            </div>
            <Button
              variant="link"
              onClick={onClickNavigate("/login")}
              className="ps-0"
            >
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SignInView;
