import { Container } from "react-bootstrap";
import NavbarTopView from "./NavbarTop.View";

export const MenuView = ({ children }: any) => {
  return (
    <>
      <NavbarTopView />
      <Container fluid>{children}</Container>
    </>
  );
};
