import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AutenticacaoModule } from "src/Contexto/auth";
import { useToast } from "src/componentes";

export const InicioCode = () => {
  const { empresaId } = AutenticacaoModule();
  const navigate = useNavigate();
  const { showToast } = useToast();
  useEffect(() => {
    const Load = async () => {
      if (empresaId == 0) {
        showToast({
          message: "Efetue o cadastro de sua empresa",
          title: "Atenção",
          variant: "Warning",
        });
        navigate("/entidade/empresa");
      }
    };

    Load();
  }, []);

  return {};
};
