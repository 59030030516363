// DropdownFilter.js
import { useState, useEffect } from "react";
import {
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { FieldError } from "react-hook-form";

interface DropdownFilterProps<T> {
  fetchData: (query: string) => Promise<T[]>;
  loading: boolean;
  labelDescription: string;
  labelId?: string;
  getDescription: (item: T) => string;
  getId: (item: T) => number;
  registeredFields: Record<string, any>;
  errorsMessageDescription: FieldError | undefined;
  onSelect?: (item: T | undefined) => void;
  showId?: boolean;
}

export function DropdownFilterComponent<T>({
  fetchData,
  loading,
  labelDescription,
  labelId,
  getDescription,
  registeredFields,
  errorsMessageDescription,
  onSelect,
  showId = false,
}: DropdownFilterProps<T>): JSX.Element {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<T[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [selected, setSelected] = useState<T>();
  const [inputReadOnly, setInputReadonly] = useState(false);
  const {
    onChange: onChangeId,
    onBlur: onBlurId,
    name: nameId,
    ref: refId,
  } = registeredFields["id"];
  const {
    onChange: onChangeDescription,
    onBlur: onBlurDescription,
    name: nameDescription,
    ref: refDescription,
  } = registeredFields["description"];

  useEffect(() => {
    if (selected) {
      setInputReadonly(true);
    } else {
      setInputReadonly(false);
    }
  }, [selected]);

  useEffect(() => {
    errorsMessageDescription = undefined;
  }, [loading]);

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (query.length >= 3) {
      const timeoutId = setTimeout(() => {
        fetchData(query)
          .then((data) => {
            setResults(data);
            setShowDropdown(true);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, 1000);

      setTypingTimeout(timeoutId);
    } else {
      setResults([]);
    }

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [query]);

  const OnInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setQuery(value);
    onChangeDescription(event);
  };

  const OnMenuItemClick = (item: T) => {
    setSelected(item);
    setShowDropdown(false);
    onSelect && onSelect(item);
  };

  const OnClick = () => {
    if (inputReadOnly === true) {
      setInputReadonly(false);
    }
  };

  const OnKeyUP = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      setSelected(undefined);
      onSelect && onSelect(undefined);
    }
  };

  return (
    <InputGroup hasValidation>
      <FloatingLabel
        label={labelId}
        className={showId === true ? "" : "d-none"}
      >
        <Form.Control
          onChange={onChangeId}
          onBlur={onBlurId}
          name={nameId}
          ref={refId}
          placeholder={labelId}
        ></Form.Control>
      </FloatingLabel>
      <FloatingLabel label={labelDescription}>
        <Form.Control
          placeholder={labelDescription}
          onChange={(e) => {
            OnInputChange(e);
          }}
          readOnly={inputReadOnly}
          onClick={OnClick}
          onKeyUp={OnKeyUP}
          isInvalid={!!errorsMessageDescription?.message}
          onBlur={onBlurDescription}
          name={nameDescription}
          ref={refDescription}
        ></Form.Control>

        {results.length > 0 && query.length >= 3 && (
          <Dropdown.Menu show={showDropdown}>
            {results.map((item, index) => (
              <Dropdown.Item key={index} onClick={() => OnMenuItemClick(item)}>
                {getDescription(item)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
        {errorsMessageDescription?.message?.toString() && (
          <Form.Control.Feedback type="invalid">
            {errorsMessageDescription?.message?.toString()}
          </Form.Control.Feedback>
        )}
      </FloatingLabel>
      {loading && (
        <InputGroupText>
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </InputGroupText>
      )}
    </InputGroup>
  );
}
