import {
  Button,
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  Placeholder,
  Row,
} from "react-bootstrap";
import UsuarioCadastroCode from "./UsuarioCadastro.Code";
import { useEffect } from "react";
import { FloatInput, HeadPage } from "src/componentes";

const UsuarioCadastroView = () => {
  const { loading, onSubmit, reset, register, handleSubmit, errors } =
    UsuarioCadastroCode();

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <HeadPage headText="Cadastro Usuário"></HeadPage>
        {!loading ? (
          <>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Nome"
                  errorsMessage={errors.nome}
                  inputMask="capitalizar"
                  {...register("nome")}
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Sobrenome"
                  errorsMessage={errors.sobrenome}
                  inputMask="capitalizar"
                  {...register("sobrenome")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <FloatInput
                  label="Email"
                  errorsMessage={errors.email}
                  inputMask="minusculas"
                  {...register("email")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-grid mt-3 mb-5">
                <Button type="submit">Confirmar</Button>
              </Col>
            </Row>
          </>
        ) : (
          <Placeholder as={FloatingLabel} animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        )}
      </Form>
    </Container>
  );
};

export default UsuarioCadastroView;
