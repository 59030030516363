import { Modal, Button } from "react-bootstrap";
import { MessageBoxIcon } from "../../interfaces/MessageBox/IMessageBoxButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faInfoCircle,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { IMessageBoxComponent } from "src/interfaces/MessageBox/IMessageBox.Component";

const MessageBoxComponent = ({
  show,
  onHide,
  onOk,
  onYes,
  onNo,
  onCancel,
  message,
  title,
  buttons,
  icon: iconType,
}: IMessageBoxComponent) => {
  const renderButton = (variant: any, label: any, onClick: any) => {
    return (
      <Button variant={variant} onClick={onClick}>
        {label}
      </Button>
    );
  };

  const getIconAndClass = (iconType: MessageBoxIcon) => {
    switch (iconType) {
      case "Warning":
        return {
          icon: faExclamationTriangle,
          classNameTitle: "text-warning",
          classNameModal: "modal-content-warning",
        };
      case "Info":
        return {
          icon: faInfoCircle,
          classNameTitle: "text-info",
          classNameModal: "modal-content-info",
        };
      case "Question":
        return {
          icon: faQuestionCircle,
          classNameTitle: "text-primary",
          classNameModal: "modal-content-question",
        };
      case "Danger":
        return {
          icon: faTimesCircle,
          classNameTitle: "text-danger",
          classNameModal: "modal-content-danger",
        };
      default:
        return {
          icon: faInfoCircle,
          classNameTitle: "text-info",
          classNameModal: "modal-content-info",
        };
    }
  };

  const { icon, classNameTitle, classNameModal } = getIconAndClass(iconType);

  const renderButtons = () => {
    switch (buttons) {
      case "Ok":
        return renderButton("secondary messagebox-btn-ok", "OK", onOk);
      case "OkCancel":
        return (
          <>
            {renderButton("secondary messagebox-btn-ok", "OK", onOk)}
            {renderButton(
              "outline-primary messagebox-btn-cancel",
              "Cancelar",
              onCancel
            )}
          </>
        );
      case "YesNo":
        return (
          <>
            {renderButton("secondary messagebox-btn-ok", "Sim", onYes)}
            {renderButton("outline-primary messagebox-btn-cancel", "Não", onNo)}
          </>
        );
      case "YesNoCancel":
        return (
          <>
            {renderButton("secondary messagebox-btn-ok", "Sim", onYes)}
            {renderButton("outline-primary messagebox-btn-cancel", "Não", onNo)}
            {renderButton(
              "outline-primary messagebox-btn-cancel",
              "Cancelar",
              onCancel
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={onHide} className={classNameModal}>
      <Modal.Header closeButton>
        <Modal.Title className={classNameTitle}>
          <FontAwesomeIcon icon={icon} /> {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>{renderButtons()}</Modal.Footer>
    </Modal>
  );
};

export default MessageBoxComponent;
