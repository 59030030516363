import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PessoaCadastroConsultaDto,
  PessoaCadastroConsultaSchema,
  PessoaCadastroDto,
  PessoaCadastroSchema,
} from "./PessoaCadastro.Dto";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PessoaAtualizar,
  PessoaBuscarPorId,
  PessoaConsultaCadastro,
  PessoaIncluir,
} from "src/services/Entidade.Service";
import {
  MapearErrosServidor,
  MapearErrosServidorCampo,
  SoNumeros,
} from "src/hook/Functions";
import { BuscarCep } from "src/services/ViaCEP";
import { useToast } from "src/componentes/Toast/Toast";

const PessoaCadastroCode = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [incluir, setIncluir] = useState(false);
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const { showToast } = useToast();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm<PessoaCadastroDto>({
    resolver: yupResolver(PessoaCadastroSchema),
  });

  const {
    register: registerConsulta,
    handleSubmit: handleSubmitConsulta,
    formState: { errors: errorsConsulta },
  } = useForm<PessoaCadastroConsultaDto>({
    resolver: yupResolver(PessoaCadastroConsultaSchema),
  });

  useEffect(() => {
    const controller = new AbortController();
    const Load = async () => {
      try {
        if (id) {
          setLoading(true);
          const response = await PessoaBuscarPorId(id);
          if (response.data.success) {
            const data = response.data.data;
            reset(data);
            setIncluir(false);
          } else {
            showToast({
              message: "Ocorreu erro ao carregar pessoa",
              title: "Erro",
              variant: "Danger",
            });
          }
        } else {
          setIncluir(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    Load();
    return () => {
      controller.abort();
    };
  }, [id]);

  const buscarCep = (cep: string) => {
    if (SoNumeros(cep).length === 8) {
      BuscarCep(cep).then((response) => {
        setValue("bairro", response.bairro);
        setValue("endereco", response.logradouro);
        setValue("nomeMunicipio", response.localidade);
        setValue("codigoMunicipio", response.ibge);
        setValue("uf", response.uf);
      });
    }
  };

  const onSubmitConsulta = async (data: PessoaCadastroConsultaDto, e: any) => {
    e.preventDefault();
    setLoading(true);
    handleClose();
    data.cnpj = SoNumeros(data.cnpj);
    PessoaConsultaCadastro(data)
      .then((response) => {
        if (response.data.success) {
          const infCons = response.data.data.infCons;
          if (infCons.cStat === "111") {
            const infCad = response.data.data.infCons.infCad;
            setValue("nome", infCad.xNome);
            setValue("nomeFantasia", infCad.xNome);
            setValue("documento", data.cnpj);
            setValue("inscricaoEstadual", infCad.ie);
            setValue("cep", infCad.ender.cep);
            setValue("bairro", infCad.ender.xBairro);
            setValue("endereco", infCad.ender.xLgr);
            setValue("enderecoNumero", infCad.ender.nro);
            setValue("codigoMunicipio", infCad.ender.cMun);
            setValue("nomeMunicipio", infCad.ender.xMun);
            setValue("uf", data.uf);
          } else {
            showToast({
              message: infCons.xMotivo,
              title: "Erro",
              variant: "Danger",
            });
          }
        } else {
          MapearErrosServidorCampo(response.data.notifications, setError);
          MapearErrosServidor(response.data.notifications, showToast);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = async (data: PessoaCadastroDto, e: any) => {
    e.preventDefault();
    setLoading(true);
    data.cep = SoNumeros(data.cep);
    data.documento = SoNumeros(data.documento);
    if (incluir === true) {
      PessoaIncluir(data)
        .then((response) => {
          if (response.data.success) {
            navigate("/entidade/pessoa");
          } else {
            MapearErrosServidorCampo(response.data.notifications, setError);
            showToast({
              message: "Ocorreu erro ao incluir cadastro",
              title: "Erro",
              variant: "Danger",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      data.id = Number(id);
      PessoaAtualizar(data)
        .then((response) => {
          if (response.data.success) {
            navigate("/entidade/pessoa");
          } else {
            MapearErrosServidorCampo(response.data.notifications, setError);
            showToast({
              message: "Ocorreu erro ao atualizar cadastro",
              title: "Erro",
              variant: "Danger",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    loading,
    onSubmit,
    handleSubmit,
    errors,
    register,
    buscarCep,
    setValue,
    show,
    handleShow,
    handleClose,
    registerConsulta,
    onSubmitConsulta,
    handleSubmitConsulta,
    errorsConsulta,
  };
};
export default PessoaCadastroCode;
