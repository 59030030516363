export const isValidDate = (dateString: string) => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!regex.test(dateString)) return false;

  const [day, month, year] = dateString.split("/").map(Number);

  const isValidMonth = month >= 1 && month <= 12;
  const isValidDay = day >= 1 && day <= 31;
  const isValidYear = year >= 1900 && year <= new Date().getFullYear();

  return isValidMonth && isValidDay && isValidYear;
};

export const MapearErrosServidorCampo = (
  serverErrors: any[],
  setError: any
) => {
  if (serverErrors) {
    serverErrors.forEach((error) => {
      if (error.tag === "campo" && error.key) {
        const key = error.key.toLowerCase();
        setError(key, {
          type: "manual",
          message: error.message,
        });
      }
    });
  }
};

export const MapearErrosServidor = (serverErrors: any[], showToast: any) => {
  if (serverErrors) {
    serverErrors
      .filter((item) => item.tag === "negocio" && item.key === "erro")
      .forEach((item) => {
        showToast({
          message: item.message,
          title: "Erro",
          variant: "Danger",
        });
      });
  }
};

export const SoNumeros = (value: string): string => {
  return value.replace(/[^\d]/g, "");
};

export const TodasMaiusculas = (value: string): string => {
  return value.toUpperCase();
};

export const TodasMinusculas = (value: string): string => {
  return value.toLowerCase();
};

export const Capitalizar = (value: string): string => {
  if (!value) {
    return value;
  }

  return value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const RemoverZerosEsquerda = (value: string): string => {
  return value.replace(/^0+/, "");
};

export const FormatarTelefone = (value: string): string => {
  const cleanPhoneNumber = SoNumeros(value).slice(0, 11);

  if (cleanPhoneNumber.length === 10) {
    return `(${cleanPhoneNumber.slice(0, 2)}) ${cleanPhoneNumber.slice(
      2,
      6
    )}-${cleanPhoneNumber.slice(6)}`;
  } else if (cleanPhoneNumber.length === 11) {
    return `(${cleanPhoneNumber.slice(0, 2)}) ${cleanPhoneNumber.slice(
      2,
      7
    )}-${cleanPhoneNumber.slice(7)}`;
  } else {
    return cleanPhoneNumber;
  }
};

export const FormatarCPF = (value: string): string => {
  const match = SoNumeros(value)
    .slice(0, 11)
    .match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);

  if (!match) {
    return value; // Não deve ocorrer
  }

  const [, part1, part2, part3, part4] = match;

  let formattedValue = "";

  if (part1) {
    formattedValue += part1;
    if (part2) {
      formattedValue += `.${part2}`;
      if (part3) {
        formattedValue += `.${part3}`;
        if (part4) {
          formattedValue += `-${part4}`;
        }
      }
    }
  }

  return formattedValue;
};

export const FormatarCEP = (value: string): string => {
  const cepLimpo = SoNumeros(value);

  const match = cepLimpo.slice(0, 8).match(/^(\d{0,5})(\d{0,3})$/);

  if (!match) {
    return cepLimpo;
  }

  const [, parte1, parte2] = match;

  let formattedValue = "";

  if (parte1) {
    formattedValue += parte1;
    if (parte2) {
      if (parte2.length <= 3) {
        formattedValue += `-${parte2}`;
      } else {
        formattedValue += `-${parte2.slice(0, 3)}`;
      }
    }
  }
  return formattedValue;
};

export const FormatarCNPJ = (cnpj: string): string => {
  const match = SoNumeros(cnpj)
    .slice(0, 14)
    .match(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})$/);

  if (!match) {
    return cnpj;
  }

  const [, part1, part2, part3, part4, part5] = match;

  let formattedCNPJ = "";

  if (part1) {
    formattedCNPJ += part1;
    if (part2) {
      formattedCNPJ += `.${part2}`;
      if (part3) {
        formattedCNPJ += `.${part3}`;
        if (part4) {
          formattedCNPJ += `/${part4}`;
          if (part5) {
            formattedCNPJ += `-${part5}`;
          }
        }
      }
    }
  }

  return formattedCNPJ;
};

export const FormatarDouble = (value: string): string => {
  if (value.length === 1) {
    return `0,0${value}`;
  }

  if (value.length === 2) {
    return `0,${value}`;
  }

  if (value.length > 2) {
    const mainPart = value.slice(0, -2);
    const centsPart = value.slice(-2);
    return `${mainPart},${centsPart}`;
  }

  return "";
};

export async function getBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      const base64WithoutPrefix = base64String.split(",")[1];
      resolve(base64WithoutPrefix);
    };
    reader.onerror = reject;
  });
}
