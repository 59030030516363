import { useEffect, useState } from "react";
import { UsuarioDto } from "../Usuario.Dto";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  UsuarioCadastroDto,
  UsuarioCadastroSchema,
} from "./UsuarioCadastro.Dto";
import { yupResolver } from "@hookform/resolvers/yup";
import { UsuarioGestaoBuscarPorId } from "src/services/Identity.Service";

const UsuarioCadastroCode = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UsuarioCadastroDto>({
    resolver: yupResolver(UsuarioCadastroSchema),
  });

  useEffect(() => {
    const controller = new AbortController();
    const Load = async () => {
      try {
        if (id) {
          setLoading(true);
          const response = await UsuarioGestaoBuscarPorId(id);
          if (response.data.success) {
            const data = response.data.data;
            reset(data);
          } else {
            response.data.notifications.map((err) => console.log(err.message));
          }
        }
      } catch (error) {
        reset(undefined);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    Load();
    return () => {
      controller.abort();
    };
  }, [id]);

  const onSubmit = async (data: any, e: any) => {
    debugger;
    e.preventDefault();
    setLoading(true);
    //const loginError = await authContext.logIn(data);

    setLoading(false);

    //if (loginError) {
    //setErroLogin(loginError);
    //} else {
    //navigate("/inicio");,
    //}
  };

  return {
    loading,
    onSubmit,
    reset,
    handleSubmit,
    errors,
    register,
  };
};

export default UsuarioCadastroCode;
