import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { faArrowLeft, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { MenuDropdownToggle } from "../MenuDropdownToggle";

interface HeadPageProps {
  headText: string;
}

export function HeadPageComponent({
  children,
  headText,
}: React.PropsWithChildren<HeadPageProps>) {
  const navigate = useNavigate();

  const handleCliNavigateBack = () => () => {
    navigate(-1);
  };

  return (
    <Row>
      <Col xs={2} sm={1} md={1} lg={1}>
        <Button variant="link" onClick={handleCliNavigateBack()}>
          <FontAwesomeIcon size="lg" icon={faArrowLeft} />
        </Button>
      </Col>
      <Col
        xs={8}
        sm={10}
        md={10}
        lg={10}
        className="d-flex align-content-center flex-wrap justify-content-center"
      >
        <div className="head-text">{headText}</div>
      </Col>
      <Col
        xs={2}
        sm={1}
        md={1}
        lg={1}
        className="d-flex align-content-center flex-wrap justify-content-end"
      >
        {children !== undefined && (
          <Dropdown>
            <MenuDropdownToggle>
              <FontAwesomeIcon icon={faEllipsisV} />
            </MenuDropdownToggle>
            <Dropdown.Menu align="end">{children}</Dropdown.Menu>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
}
