import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Placeholder,
  Row,
} from "react-bootstrap";
import { DropdownFilter, FloatInput, HeadPage } from "src/componentes";
import EmpresaCode from "./Empresa.Code";
import { ConsultaMunicipioModule } from "src/hook/Consulta/ConsultaMunicipio.Module";
import { MunicipioDto } from "../Municipio/Municipio.Dto";
import { useNavigate } from "react-router-dom";

const EmpresaView = () => {
  const {
    loading,
    register,
    errors,
    handleSubmit,
    onSubmit,
    setValue,
    buscarCep,
  } = EmpresaCode();
  const { MunicipioBuscar, loadingMunicipio } = ConsultaMunicipioModule();
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <HeadPage headText="Empresa"></HeadPage>
          {!loading ? (
            <>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FloatInput
                    label="Nome"
                    errorsMessage={errors.nome}
                    {...register("nome")}
                    inputMask="capitalizar"
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FloatInput
                    label="Nome Fantasia"
                    errorsMessage={errors.nomeFantasia}
                    {...register("nomeFantasia")}
                    inputMask="capitalizar"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FloatInput
                    label="Apelido"
                    errorsMessage={errors.apelido}
                    {...register("apelido")}
                    inputMask="capitalizar"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FloatInput
                    label="CNPJ"
                    errorsMessage={errors.documento}
                    {...register("documento")}
                    inputMask="cnpj"
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FloatInput
                    label="Inscrição Estadual"
                    errorsMessage={errors.inscricaoEstadual}
                    {...register("inscricaoEstadual")}
                    inputMask="int"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <FloatInput
                    label="CEP"
                    errorsMessage={errors.cep}
                    onInputChange={(value: string) => {
                      buscarCep(value);
                    }}
                    {...register("cep")}
                    inputMask="cep"
                  />
                </Col>
                <Col xs={12} sm={9} md={9} lg={9}>
                  <FloatInput
                    label="Bairro"
                    errorsMessage={errors.bairro}
                    {...register("bairro")}
                    inputMask="capitalizar"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={8} md={9} lg={9}>
                  <FloatInput
                    label="Endereço"
                    errorsMessage={errors.endereco}
                    {...register("endereco")}
                    inputMask="capitalizar"
                  />
                </Col>
                <Col xs={12} sm={4} md={3} lg={3}>
                  <FloatInput
                    label="Número"
                    errorsMessage={errors.enderecoNumero}
                    {...register("enderecoNumero")}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FloatInput
                    label="Endereço Complemento"
                    errorsMessage={errors.enderecoComplemento}
                    {...register("enderecoComplemento")}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={10} md={10} lg={10}>
                  <DropdownFilter<MunicipioDto>
                    fetchData={MunicipioBuscar}
                    loading={loadingMunicipio}
                    labelDescription="Município"
                    getDescription={(item: MunicipioDto) => item.nomeMunicipio}
                    getId={(item: MunicipioDto) => item.codigoMunicipio}
                    registeredFields={{
                      id: register("codigoMunicipio"),
                      description: register("nomeMunicipio"),
                    }}
                    errorsMessageDescription={errors.codigoMunicipio}
                    onSelect={(item: MunicipioDto | undefined) => {
                      if (item !== undefined) {
                        setValue(
                          "codigoMunicipio",
                          item.codigoMunicipio?.toString()
                        );
                        setValue("nomeMunicipio", item.nomeMunicipio);
                        setValue("uf", item.ufMunicipio);
                      } else {
                        setValue("codigoMunicipio", "");
                      }
                    }}
                  ></DropdownFilter>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2}>
                  <FloatInput
                    label="UF"
                    errorsMessage={errors.uf}
                    {...register("uf")}
                    inputMask="maiusculas"
                    maxLength={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="d-grid mt-3 mb-5">
                  <Button type="submit">Confirmar</Button>
                </Col>
              </Row>
            </>
          ) : (
            <Placeholder as={FloatingLabel} animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          )}
        </Form>
      </Container>
    </>
  );
};
export default EmpresaView;
