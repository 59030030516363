import { FloatingLabel, Form } from "react-bootstrap";
import { FieldError } from "react-hook-form";

type SelectProps = {
  label: string;
  options: OptionProps[];
  register: any;
  errorsMessage?: FieldError | undefined;
};

type OptionProps = {
  value?: string;
  label: string;
};

export const FloatSelectComponent = ({
  label,
  options,
  register,
  errorsMessage,
}: React.PropsWithChildren<SelectProps>) => {
  return (
    <FloatingLabel label={label}>
      <Form.Select {...register} isInvalid={!!errorsMessage?.message}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
      {errorsMessage?.message?.toString() && (
        <Form.Control.Feedback type="invalid">
          {errorsMessage?.message?.toString()}
        </Form.Control.Feedback>
      )}
    </FloatingLabel>
  );
};

export default FloatSelectComponent;
