import { useEffect, useState } from "react";
import { UsuarioDto } from "./Usuario.Dto";
import { UsuarioGestaoBuscarTodos } from "src/services/Identity.Service";

const UsuarioCode = () => {
  const [usuarioData, setUsuarioData] = useState<UsuarioDto[] | undefined>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const Load = async () => {
      setLoading(true);
      try {
        const response = await UsuarioGestaoBuscarTodos();
        if (response.data.success) {
          const data = response.data.data;
          setUsuarioData(data);
        } else {
          response.data.notifications.map((err) => console.log(err.message));
        }
      } catch (error) {
        setUsuarioData(undefined);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    Load();
    return () => {
      controller.abort();
    };
  }, []);

  return { usuarioData, loading };
};

export default UsuarioCode;
