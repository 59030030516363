import { Button, Spinner } from "react-bootstrap";

export const ButtonSpinnerComponent = ({
  onClick,
  children,
  loading,
  ...rest
}: any) => {
  return (
    <Button onClick={onClick} disabled={loading} {...rest}>
      {loading ? (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        children
      )}
    </Button>
  );
};
