import { Container, FormLabel } from "react-bootstrap";
import { InicioCode } from "./Inicio.Code";

const InicioView = () => {
  const {} = InicioCode();

  return (
    <Container>
      <FormLabel>Inicio</FormLabel>
    </Container>
  );
};

export default InicioView;
