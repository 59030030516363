import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Contexto/auth";
import RefreshTokenComponent from "./componentes/RefreshToken/RefreshToken.Component";
import ToastProvider from "./componentes/Toast/Toast";
import { Rotas } from "./route/Rotas";
import "./custom.scss";
import { useEffect } from "react";
import { TemaModule } from "./hook/Tema.Module";
import { MenuProvider } from "./Contexto/Menu.Context";
import { MessageBoxProvider } from "./Contexto/MessageBox.Context";

function App() {
  const { CarregarTema } = TemaModule();

  useEffect(() => {
    const Load = () => {
      CarregarTema();
    };
    Load();
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastProvider>
          <RefreshTokenComponent>
            <MenuProvider>
              <MessageBoxProvider>
                <Rotas />
              </MessageBoxProvider>
            </MenuProvider>
          </RefreshTokenComponent>
        </ToastProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
