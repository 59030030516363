import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SigninDto } from "./Signin.Dto";
import { useToast } from "../../../componentes/Toast/Toast";
import { AutenticacaoModule } from "src/Contexto/auth";

const SiginInCode = () => {
  const [loading, setLoading] = useState(false);
  const { signIn } = AutenticacaoModule();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const onClickNavigate = (route: string) => () => {
    navigate(route);
  };

  const onSubmit = (data: SigninDto, e: any) => {
    e.preventDefault();
    setLoading(true);
    signIn(data)
      .then((response) => {
        if (response.success) {
          navigate("/login");
        } else {
          showToast({
            message: "Ocorreu erro ao efetuar o cadastro",
            title: "Erro",
            variant: "Danger",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    onSubmit,
    loading,
    onClickNavigate,
  };
};
export default SiginInCode;
