import {
  Button,
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  Modal,
  Placeholder,
  Row,
} from "react-bootstrap";
import { FloatInput, HeadPage } from "src/componentes";
import CertificadoCode from "./Certificado.Code";

const CertificadoView = () => {
  const {
    loading,
    register,
    handleSubmitIncluir,
    onSubmitIncluir,
    registerIncluir,
    errorsIncluir,
    show,
    handleShow,
    handleClose,
  } = CertificadoCode();

  return (
    <Container>
      <HeadPage headText="Certificado">
        <Dropdown.Item onClick={handleShow}>Novo Certificado...</Dropdown.Item>
      </HeadPage>
      <Modal show={show} onHide={handleClose}>
        <Form noValidate onSubmit={handleSubmitIncluir(onSubmitIncluir)}>
          <Modal.Header closeButton>
            <Modal.Title>Novo Certificado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Selecione (*.pfx)</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".pfx"
                    isInvalid={!!errorsIncluir?.arquivo?.message}
                    {...registerIncluir("arquivo")}
                  />
                  {errorsIncluir?.arquivo?.message?.toString() && (
                    <Form.Control.Feedback type="invalid">
                      {errorsIncluir?.arquivo?.message?.toString()}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <FloatInput
                  label="Chave"
                  errorsMessage={errorsIncluir.chave}
                  {...registerIncluir("chave")}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {!loading ? (
        <>
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <FloatInput label="Nome" {...register("nome")} readOnly={true} />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <FloatInput
                label="Validade até"
                {...register("dataHoraValidade")}
                inputMask="datahora"
                readOnly={true}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Placeholder as={FloatingLabel} animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
      )}
    </Container>
  );
};
export default CertificadoView;
