import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AcessoLogin,
  AcessoRefreshToken,
  UsuarioAdicionar,
} from "../services/Identity.Service";
import { ILogin } from "../interfaces/ILogin";
import { IToken } from "../interfaces/IToken";
import { SigninDto } from "../pages/Publica/SignIn/Signin.Dto";
import jwtDecode from "jwt-decode";
import { UserRole } from "../enum/UserRole";
import ApiResponse from "src/interfaces/ApiResponse";
import { useNavigate } from "react-router-dom";

interface IAuthContext {
  user: IToken | null;
  isLogged: boolean;
  isLoaded: boolean;
  role: UserRole;
  refreshToken: () => Promise<void | string>;
  logIn: (login: ILogin) => Promise<ApiResponse>;
  signIn: (signin: SigninDto) => Promise<ApiResponse>;
  logOut: () => void;
  userId: number;
  empresaId: number;
}

interface ITokenDecode {
  name: string;
  exp: number;
  role: string;
  nameid: number;
  empresaid: number;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<IToken | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const navigate = useNavigate();

  const isTokenValid = useCallback((token: IToken | null): boolean => {
    if (token == null) return false;
    const expirationDate = getExpirationDate(token);
    const currentDate = Date.now();
    return currentDate < expirationDate.getTime();
  }, []);

  const getExpirationDate = (token: IToken): Date => {
    const decodeAccessToken = jwtDecode<ITokenDecode>(token.token_access);
    const decodeRefreshToken = jwtDecode<ITokenDecode>(token.token_refresh);

    const accessTokenExpiration = new Date(decodeAccessToken.exp * 1000);
    const refreshTokenExpiration = new Date(decodeRefreshToken.exp * 1000);

    return accessTokenExpiration < refreshTokenExpiration
      ? accessTokenExpiration
      : refreshTokenExpiration;
  };

  useEffect(() => {
    const Load = async () => {
      const userToken = localStorage.getItem("userToken");
      if (userToken) {
        const parsedToken: IToken = JSON.parse(userToken);
        if (isTokenValid(parsedToken)) {
          setUser(parsedToken);
        } else {
          setUser(null);
          localStorage.removeItem("userToken");
        }
      } else {
        navigate("/login");
      }
      setIsLoaded(true);
    };
    Load();
  }, [isTokenValid]);

  const refreshToken = useCallback(async () => {
    try {
      if (user === null) {
        throw new Error("Usuário não autenticado");
      }

      const response = await AcessoRefreshToken({
        token_refresh: user.token_refresh,
      });

      if (response.data.success) {
        const data = response.data.data;
        localStorage.setItem("userToken", JSON.stringify(data));
        setUser(data);
      } else {
        throw new Error("Falha ao renovar o token");
      }
    } catch (error: any) {
      setUser(null);
      const errors = error.response?.data?.errors || [];
      const errorMessage =
        errors.length > 0 ? errors[0].message : "Ocorreu um erro na requisição";
      return errorMessage;
    }
  }, [user]);

  const logIn = useCallback(async (login: ILogin) => {
    const response = await AcessoLogin(login);

    if (response.success) {
      const data = response.data;
      localStorage.setItem("userToken", JSON.stringify(data));
      setUser(data);
    }
    return response;
  }, []);

  const signIn = useCallback(async (signin: SigninDto) => {
    return await UsuarioAdicionar(signin);
  }, []);

  const logOut = useCallback(async () => {
    setUser(null);
    localStorage.removeItem("userToken");
  }, []);

  const getUserRole = useCallback((): UserRole => {
    if (user === null) return UserRole.User;
    if (isTokenValid(user)) {
      const decodeAccessToken = jwtDecode<ITokenDecode>(user.token_access);
      if (decodeAccessToken && decodeAccessToken.role) {
        return decodeAccessToken.role as UserRole;
      }
    }
    return UserRole.User;
  }, [user, isTokenValid]);

  const getEmpresaId = useCallback((): number => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      const parsedToken: IToken = JSON.parse(userToken);
      if (isTokenValid(parsedToken)) {
        const decodeAccessToken = jwtDecode<ITokenDecode>(
          parsedToken.token_access
        );
        if (decodeAccessToken && decodeAccessToken.empresaid) {
          return decodeAccessToken.empresaid;
        }
      }
    }
    return 0;
  }, [user]);

  const getUserId = useCallback((): number => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      const parsedToken: IToken = JSON.parse(userToken);
      if (isTokenValid(parsedToken)) {
        const decodeAccessToken = jwtDecode<ITokenDecode>(
          parsedToken.token_access
        );
        if (decodeAccessToken && decodeAccessToken.nameid) {
          return decodeAccessToken.nameid;
        }
      }
    }
    return 0;
  }, [user]);

  const value = useMemo(
    () => ({
      user: user,
      isLogged: user !== null && isTokenValid(user),
      isLoaded: isLoaded,
      role: getUserRole(),
      refreshToken,
      logIn,
      signIn,
      logOut,
      userId: getUserId(),
      empresaId: getEmpresaId(),
    }),
    [user, logIn, signIn, logOut, getUserRole, isTokenValid, refreshToken]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AutenticacaoModule = () => useContext(AuthContext);
