import {
  Button,
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  Modal,
  Placeholder,
  Row,
} from "react-bootstrap";
import PessoaCadastroCode from "./PessoaCadastro.Code";
import {
  DropdownFilter,
  FloatInput,
  FloatSelect,
  HeadPage,
} from "src/componentes";
import { MunicipioDto } from "../../Municipio/Municipio.Dto";
import { ConsultaMunicipioModule } from "src/hook/Consulta/ConsultaMunicipio.Module";

const PessoaCadastroView = () => {
  const {
    loading,
    onSubmit,
    register,
    handleSubmit,
    errors,
    buscarCep,
    setValue,
    show,
    handleShow,
    handleClose,
    onSubmitConsulta,
    registerConsulta,
    handleSubmitConsulta,
    errorsConsulta,
  } = PessoaCadastroCode();

  const { MunicipioBuscar, loadingMunicipio } = ConsultaMunicipioModule();

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Form noValidate onSubmit={handleSubmitConsulta(onSubmitConsulta)}>
          <Modal.Header closeButton>
            <Modal.Title>Consulta Cadastro</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <FloatInput
                  label="CNPJ"
                  errorsMessage={errorsConsulta.cnpj}
                  {...registerConsulta("cnpj")}
                  inputMask="cnpj"
                />
              </Col>
              <Col xs={12}>
                <FloatInput
                  label="UF"
                  errorsMessage={errorsConsulta.uf}
                  {...registerConsulta("uf")}
                  maxLength={2}
                  inputMask="maiusculas"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <HeadPage headText="Cadastro Pessoa">
          <Dropdown.Item as={Button} onClick={handleShow}>
            Importar...
          </Dropdown.Item>
        </HeadPage>

        {!loading ? (
          <>
            {" "}
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Nome"
                  errorsMessage={errors.nome}
                  {...register("nome")}
                  inputMask="capitalizar"
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Nome Fantasia"
                  errorsMessage={errors.nomeFantasia}
                  {...register("nomeFantasia")}
                  inputMask="capitalizar"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FloatInput
                  label="Apelido"
                  errorsMessage={errors.apelido}
                  {...register("apelido")}
                  inputMask="capitalizar"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="CNPJ/CPF"
                  errorsMessage={errors.documento}
                  {...register("documento")}
                  inputMask="cnpj-cpf"
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Inscrição Estadual"
                  errorsMessage={errors.inscricaoEstadual}
                  {...register("inscricaoEstadual")}
                  inputMask="text"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <FloatInput
                  label="CEP"
                  errorsMessage={errors.cep}
                  onInputChange={(value: string) => {
                    buscarCep(value);
                  }}
                  {...register("cep")}
                  inputMask="cep"
                />
              </Col>
              <Col xs={12} sm={9} md={9} lg={9}>
                <FloatInput
                  label="Bairro"
                  errorsMessage={errors.bairro}
                  {...register("bairro")}
                  inputMask="capitalizar"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} md={9} lg={9}>
                <FloatInput
                  label="Endereço"
                  errorsMessage={errors.endereco}
                  {...register("endereco")}
                  inputMask="capitalizar"
                />
              </Col>
              <Col xs={12} sm={4} md={3} lg={3}>
                <FloatInput
                  label="Número"
                  errorsMessage={errors.enderecoNumero}
                  {...register("enderecoNumero")}
                  inputMask="maiusculas"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FloatInput
                  label="Endereço Complemento"
                  errorsMessage={errors.enderecoComplemento}
                  {...register("enderecoComplemento")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={10} md={10} lg={10}>
                <DropdownFilter<MunicipioDto>
                  fetchData={MunicipioBuscar}
                  loading={loadingMunicipio}
                  labelDescription="Município"
                  getDescription={(item: MunicipioDto) => item.nomeMunicipio}
                  getId={(item: MunicipioDto) => item.codigoMunicipio}
                  registeredFields={{
                    id: register("codigoMunicipio"),
                    description: register("nomeMunicipio"),
                  }}
                  errorsMessageDescription={errors.codigoMunicipio}
                  onSelect={(item: MunicipioDto | undefined) => {
                    if (item !== undefined) {
                      setValue(
                        "codigoMunicipio",
                        item.codigoMunicipio?.toString()
                      );
                      setValue("nomeMunicipio", item.nomeMunicipio);
                      setValue("uf", item.ufMunicipio);
                    } else {
                      setValue("codigoMunicipio", "");
                    }
                  }}
                ></DropdownFilter>
              </Col>
              <Col xs={12} sm={2} md={2} lg={2}>
                <FloatInput
                  label="UF"
                  errorsMessage={errors.uf}
                  {...register("uf")}
                  inputMask="maiusculas"
                  maxLength={2}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FloatSelect
                  label="Perfil"
                  options={[
                    { label: "Selecione" },
                    { value: "1", label: "Cliente" },
                    { value: "2", label: "Fornecedor" },
                    { value: "3", label: "Cliente e Fornecedor" },
                  ]}
                  register={register("perfilPessoa")}
                  errorsMessage={errors.perfilPessoa}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-grid mt-3 mb-5">
                <Button type="submit">Confirmar</Button>
              </Col>
            </Row>
          </>
        ) : (
          <Placeholder as={FloatingLabel} animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        )}
      </Form>
    </Container>
  );
};
export default PessoaCadastroView;
