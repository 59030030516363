import axios from "axios";
import { IToken } from "../interfaces/IToken";

const api = axios.create({});

const addTokenToRequestConfig = (config: any) => {
  const userToken = localStorage.getItem("userToken");
  if (userToken) {
    const parsedToken: IToken = JSON.parse(userToken);
    config.headers.Authorization = `Bearer ${parsedToken.token_access}`;
  }
  return config;
};

api.interceptors.request.use(addTokenToRequestConfig);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        return Promise.resolve({
          ...error.response,
          data: {
            success: false,
            notifications: error.response.data.notifications || [],
          },
        });
      } else {
        console.error("Erro de resposta:", error);

        const formattedError = {
          success: false,
          notifications: [
            {
              tag: "response_error",
              key: "status_code",
              message: `Erro de resposta: ${error.response.status}`,
            },
          ],
        };

        return Promise.resolve({
          data: formattedError,
          status: error.response.status,
          statusText: error.response.statusText,
          headers: error.response.headers,
          config: error.config,
        });
      }
    } else if (error.request) {
      console.error("Erro de solicitação:", error);
      const formattedError = {
        success: false,
        notifications: [
          {
            tag: "request_error",
            key: "no_response",
            message: "A solicitação não recebeu resposta",
          },
        ],
      };

      return Promise.resolve({
        data: formattedError,
        status: 500,
        statusText: "Internal Server Error",
        headers: {},
        config: error.config,
      });
    } else {
      console.error("Erro geral:", error);

      const formattedError = {
        success: false,
        notifications: [
          {
            tag: "general_error",
            key: "setup_failure",
            message: `Erro geral: ${error.message}`,
          },
        ],
      };

      return Promise.resolve({
        data: formattedError,
        status: 500,
        statusText: "Internal Server Error",
        headers: {},
        config: error.config,
      });
    }
  }
);

export default api;
