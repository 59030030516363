import { validateCPF, validatePhone } from "validations-br";
import * as yup from "yup";

export interface MinhaContaDto {
  id: number;
  documento: string;
  nome: string;
  sobrenome: string;
  dataNascimento: string;
  email: string;
  numeroTelefone: string;
}

export const MinhaContaSchema = yup.object().shape({
  nome: yup
    .string()
    .required("Digite o nome.")
    .min(3, "O nome deve ter no mínimo 3 caracteres")
    .max(20, "O nome deve ter no máximo 20 caracteres."),
  sobrenome: yup
    .string()
    .required("Digite o nome.")
    .min(3, "O nome deve ter no mínimo 3 caracteres")
    .max(20, "O nome deve ter no máximo 20 caracteres."),
  email: yup
    .string()
    .email("Digite um e-mail válido.")
    .required("Digite seu e-mail."),
  dataNascimento: yup
    .date()
    .typeError("Digite uma data válida.")
    .required("Digite a data de nascimento.")
    .max(new Date(), "A data de nascimento não pode ser no futuro."),
  documento: yup
    .string()
    .required("CPF é obrigatório")
    .test("cpf", "CPF inválido", (value) => validateCPF(value)),
  numeroTelefone: yup
    .string()
    .required("Número telefone é obrigatório")
    .test("fone", "Telefone inválido", (value) => validatePhone(value)),
});
