import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import MessageBoxComponent from "src/componentes/MessageBox/MessageBox.Component";
import {
  MessageBoxButton,
  MessageBoxIcon,
} from "src/interfaces/MessageBox/IMessageBoxButton";

export interface IMessageBoxContext {
  ShowMessage: (
    msg: string,
    title: string,
    buttons: MessageBoxButton,
    icon: MessageBoxIcon,
    callbacksParam: Callbacks
  ) => void;
}

interface Callbacks {
  onOk?: () => void;
  onYes?: () => void;
  onNo?: () => void;
  onCancel?: () => void;
}

const MessageBoxContext = createContext<IMessageBoxContext>(
  {} as IMessageBoxContext
);

export const MessageBoxProvider = ({ children }: any) => {
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [buttons, setButtons] = useState<MessageBoxButton>("Ok");
  const [icon, setIcon] = useState<MessageBoxIcon>("Info");

  const [callbacks, setCallbacks] = useState<Callbacks>({
    onOk: undefined,
    onYes: undefined,
    onNo: undefined,
    onCancel: undefined,
  });

  const ShowMessage = useCallback(
    (
      msg: string,
      title: string,
      buttons: MessageBoxButton,
      icon: MessageBoxIcon,
      callbacksParam: Callbacks
    ) => {
      setMessage(msg);
      setTitle(title);
      setButtons(buttons);
      setIcon(icon);
      setCallbacks({
        onOk: callbacksParam.onOk
          ? () => {
              callbacksParam.onOk!();
              handleClose();
            }
          : handleClose,
        onYes: callbacksParam.onYes
          ? () => {
              callbacksParam.onYes!();
              handleClose();
            }
          : handleClose,
        onNo: callbacksParam.onNo
          ? () => {
              callbacksParam.onNo!();
              handleClose();
            }
          : handleClose,
        onCancel: callbacksParam.onCancel
          ? () => {
              callbacksParam.onCancel!();
              handleClose();
            }
          : handleClose,
      });
      setShowMessageBox(true);
    },
    []
  );

  const handleClose = useCallback(() => {
    setShowMessageBox(false);
    setMessage("");
    setCallbacks({
      onOk: undefined,
      onYes: undefined,
      onNo: undefined,
      onCancel: undefined,
    });
  }, []);

  const contextValue = useMemo(() => ({ ShowMessage }), [ShowMessage]);

  return (
    <MessageBoxContext.Provider value={contextValue}>
      {children}
      {showMessageBox && (
        <MessageBoxComponent
          show={showMessageBox}
          onHide={handleClose}
          onOk={callbacks.onOk}
          onYes={callbacks.onYes}
          onNo={callbacks.onNo}
          onCancel={callbacks.onCancel}
          message={message}
          title={title}
          buttons={buttons}
          icon={icon}
        />
      )}
    </MessageBoxContext.Provider>
  );
};

export const useMessageBox = () => useContext(MessageBoxContext);
