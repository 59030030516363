import { useEffect, useState } from "react";
import { AutenticacaoModule } from "src/Contexto/auth";

const REFRESH_TIMER = 1 * 60 * 1000;
const INITIAL_MINUTE_COUNTDOWN = 9;

function RefreshTokenComponent({ children }: any) {
  const { refreshToken, isLogged } = AutenticacaoModule();
  const [countdownToRefreshUserToken, setCountdownToRefreshUserToken] =
    useState<number>(INITIAL_MINUTE_COUNTDOWN);

  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      const currentTimer = countdownToRefreshUserToken - 1;
      if (currentTimer > 0 && isLogged) {
        setCountdownToRefreshUserToken(currentTimer);
        return;
      }
      refreshToken().finally(() => {
        setCountdownToRefreshUserToken(INITIAL_MINUTE_COUNTDOWN);
      });
    }, REFRESH_TIMER);
    return () => {
      clearTimeout(refreshTimer);
    };
  }, [refreshToken, countdownToRefreshUserToken]);

  return children;
}

export default RefreshTokenComponent;
