import { useEffect, useState } from "react";
import {
  CertificadoInfoDto,
  CertificadoIncluirSchema,
  CertificadoIncluirDto,
  CertificadoInluirInputDto,
} from "./Certificado.Dto";
import {
  CertificadoAtualizar,
  CertificadoBuscarInfo,
  CertificadoIncluir,
} from "src/services/Entidade.Service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "src/componentes";
import {
  MapearErrosServidor,
  MapearErrosServidorCampo,
  getBase64,
} from "src/hook/Functions";
import { useNavigate } from "react-router-dom";

const CertificadoCode = () => {
  const [loading, setLoading] = useState(false);
  const [incluir, setIncluir] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { register, reset } = useForm<CertificadoInfoDto>({
    defaultValues: undefined,
  });

  const {
    register: registerIncluir,
    handleSubmit: handleSubmitIncluir,
    formState: { errors: errorsIncluir },
    setError: setErrorIncluir,
  } = useForm<CertificadoIncluirDto>({
    resolver: yupResolver(CertificadoIncluirSchema),
    defaultValues: undefined,
  });
  const navigate = useNavigate();
  const { showToast } = useToast();

  useEffect(() => {
    const controller = new AbortController();
    const Load = async () => {
      setLoading(true);
      await CertificadoBuscarInfo()
        .then((response) => {
          if (response.data.success) {
            const data = response.data.data as CertificadoInfoDto;
            reset(data);
            setIncluir(false);
          } else {
            setIncluir(true);
            MapearErrosServidor(response.data.notifications, showToast);
          }
        })
        .catch((error) => {
          console.log(error);
          showToast({
            message: "Ocorreu erro ao buscar certificado",
            title: "Erro",
            variant: "Danger",
          });
          return undefined;
        })
        .finally(() => {
          setLoading(false);
        });
    };
    Load();
    return () => {
      controller.abort();
    };
  }, []);

  const onSubmitIncluir = async (data: CertificadoIncluirDto, e: any) => {
    const base64Arquivo = await getBase64(data.arquivo[0]);
    const certificado: CertificadoInluirInputDto = {
      arquivo: base64Arquivo as string,
      chave: data.chave,
    };

    if (incluir === true) {
      CertificadoIncluir(certificado).then((response) => {
        if (response.data.success) {
          navigate("/inicio");
        } else {
          MapearErrosServidorCampo(
            response.data.notifications,
            setErrorIncluir
          );
          MapearErrosServidor(response.data.notifications, showToast);
        }
      });
    } else {
      CertificadoAtualizar(certificado).then((response) => {
        if (response.data.success) {
          handleClose();
          navigate("/inicio");
        } else {
          MapearErrosServidorCampo(
            response.data.notifications,
            setErrorIncluir
          );
          MapearErrosServidor(response.data.notifications, showToast);
        }
      });
    }
  };

  return {
    loading,
    register,
    handleSubmitIncluir,
    onSubmitIncluir,
    registerIncluir,
    errorsIncluir,
    show,
    handleShow,
    handleClose,
  };
};
export default CertificadoCode;
