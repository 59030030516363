import {
  Button,
  Col,
  Container,
  Form,
  Placeholder,
  Row,
} from "react-bootstrap";
import MinhaContaCode from "./MinhaConta.Code";
import { FloatInput, HeadPage } from "src/componentes";

const MinhaContaView = () => {
  const { loading, register, errors, handleSubmit, onSubmit } =
    MinhaContaCode();

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <HeadPage headText="Minha Conta"></HeadPage>
        {!loading ? (
          <>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Nome"
                  errorsMessage={errors.nome}
                  {...register("nome")}
                  inputMask="capitalizar"
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Sobrenome"
                  errorsMessage={errors.sobrenome}
                  {...register("sobrenome")}
                  inputMask="capitalizar"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Data Nascimento"
                  errorsMessage={errors.dataNascimento}
                  {...register("dataNascimento")}
                  inputMask="data"
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="CPF"
                  errorsMessage={errors.documento}
                  {...register("documento")}
                  inputMask="cpf"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Telefone"
                  errorsMessage={errors.numeroTelefone}
                  {...register("numeroTelefone")}
                  inputMask="fone"
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <FloatInput
                  label="Email"
                  errorsMessage={errors.email}
                  {...register("email")}
                  inputMask="minusculas"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-grid mt-3 mb-5">
                <Button type="submit">Confirmar</Button>
              </Col>
            </Row>
          </>
        ) : (
          <Placeholder animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        )}
      </Form>
    </Container>
  );
};

export default MinhaContaView;
