import { useEffect, useState } from "react";
import {
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Placeholder,
  Row,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { HeadPage } from "src/componentes";
import { IRoute } from "src/interfaces/IRoute";
import PessoaCode from "./Pessoa.Code";

const PessoaView = () => {
  const { register, errors, handleSubmit, onSubmit, loading, pessoaData } =
    PessoaCode();
  const navigate = useNavigate();
  const [menuItens, setMenuItens] = useState<IRoute[]>([]);
  const [radioValue, setRadioValue] = useState("1");
  const radios = [
    { name: "Cliente", value: "1" },
    { name: "Fornecedor", value: "2" },
    { name: "Ambos", value: "3" },
  ];

  useEffect(() => {
    setMenuItens([
      { name: "Nova Pessoa...", route: "/entidade/pessoa/cadastro" },
    ]);
  }, []);

  const handleClickNavigate = (route: string) => () => {
    navigate(route);
  };

  const handleClickAlterar = (id: number) => () => {
    navigate(`/entidade/pessoa/cadastro?id=${id}`);
  };

  return (
    <Container>
      <HeadPage headText="Pessoa">
        {menuItens !== undefined &&
          menuItens.map((item) => (
            <Dropdown.Item
              key={item.name}
              onClick={handleClickNavigate(item.route)}
            >
              {item.name}
            </Dropdown.Item>
          ))}
      </HeadPage>
      <Row>
        <Col>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <InputGroup hasValidation>
              <FloatingLabel controlId="pesquisar" label="Pesquisar">
                <Form.Control
                  type="text"
                  placeholder="Pesquisar"
                  isInvalid={!!errors.nome?.message?.toString()}
                  {...register("nome")}
                />
                {errors.nome?.message?.toString() && (
                  <Form.Control.Feedback type="invalid">
                    {errors.nome?.message?.toString()}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonGroup className="mb-2">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                className="agiliza-check-button"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row>

      <Row>
        {!loading ? (
          <Col>
            {pessoaData && pessoaData.length > 0 ? (
              <Table responsive striped hover>
                <thead>
                  <tr>
                    <th className="col-10">Nome</th>
                    <th className="d-none d-sm-table-cell col-2">Município</th>
                  </tr>
                </thead>
                <tbody>
                  {pessoaData?.map((item) => (
                    <tr
                      key={item.id}
                      onClick={handleClickAlterar(item.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="col-10">
                        {item.nome} {item.nomeFantasia}
                      </td>
                      <td className="d-none d-sm-table-cell col-2">
                        {item.nomeMunicipio}-{item.uf}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Form.Text>Sem Dados</Form.Text>
            )}
          </Col>
        ) : (
          <Placeholder as={FloatingLabel} animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        )}
      </Row>
    </Container>
  );
};
export default PessoaView;
