import { MinhaContaDto } from "src/pages/Privada/MinhaConta/MinhaConta.Dto";
import * as yup from "yup";

export interface UsuarioCadastroDto extends MinhaContaDto {}

export const UsuarioCadastroSchema = yup.object().shape({
  id: yup
    .number()
    .typeError("Digite o código.")
    .integer("Digite o código.")
    .required("Digite o código.")
    .moreThan(0, "Digite o código."),
  nome: yup.string().required("Digite o nome."),
  email: yup
    .string()
    .email("Digite um e-mail válido.")
    .required("Digite seu e-mail."),
});
